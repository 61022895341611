import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-newport-news-virginia.png'
import image0 from "../../images/cities/scale-model-of-the-mariners'-museum-in-newport-news-virginia.png"
import image1 from "../../images/cities/scale-model-of-riverview-farm-park-in-newport-news-virginia.png"
import image2 from "../../images/cities/scale-model-of-virginia-war-museum-in-newport-news-virginia.png"
import image3 from "../../images/cities/scale-model-of-huntington-park-in-newport-news-virginia.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Newport News'
            state='Virginia'
            image={image}
            lat='37.0870821'
            lon='-76.47301219999997'
            attractions={ [{"name": "The Mariners' Museum", "vicinity": "100 Museum Dr, Newport News", "types": ["museum", "park", "movie_theater", "cafe", "food", "point_of_interest", "establishment"], "lat": 37.0551904, "lng": -76.48808409999998}, {"name": "Riverview Farm Park", "vicinity": "Riverview Pkwy, Newport News", "types": ["park", "point_of_interest", "establishment"], "lat": 37.0882881, "lng": -76.5281574}, {"name": "Virginia War Museum", "vicinity": "9285 Warwick Blvd, Newport News", "types": ["museum", "point_of_interest", "establishment"], "lat": 37.0193649, "lng": -76.45364660000001}, {"name": "Huntington Park", "vicinity": "Riverpark Rd, Newport News", "types": ["park", "point_of_interest", "establishment"], "lat": 37.0188505, "lng": -76.45413959999996}] }
            attractionImages={ {"The Mariners' Museum":image0,"Riverview Farm Park":image1,"Virginia War Museum":image2,"Huntington Park":image3,} }
       />);
  }
}